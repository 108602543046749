/* You can add global styles to this file, and also import other style files */
@import "styles/variables";

@import "node_modules/bootstrap/scss/bootstrap.scss";

html,
body {
    margin: 0px;
    padding: 0px;
    font-family: "Open Sans", sans-serif !important;

    * {
        font-family: "Open Sans", sans-serif !important;

    }

}

.body.container {
    min-height: calc(100vh - $header-height);
}

.icon-img {
    width: 1rem;
    height: 1rem;
}

.icon-img-md {
    width: 2rem;
    height: 2rem;
}

.icon-img-lg {
    width: 2.5rem;
    height: 2.5rem;
}

.container {
    padding: 2rem 1rem;
}

.form-error {
    color: $error;
    font-size: 0.75rem;
}

.ng-touched.ng-invalid {
    border-color: #dc3545;
}


.form-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .sr-only {
        display: none;
    }

    label {
        margin-bottom: 8px;
        font-weight: 600;
    }

    .form-group {
        .input-group {
            select {
                max-width: 80px;
            }
        }

        input,
        select {
            height: 50px;
        }
    }

}

.flex-1 {
    flex: 1;
}

button {
    text-transform: uppercase;
    font-weight: 300 !important;
    color: white !important;
}

app-personal-info,
app-submit {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.absolute {
    position: absolute;
}